<template>
	<div class="about">
		<Banner
			class="banner-style"
			enTitle="About VIEWpoint"
			cnTitle="关于VIEWpoint新观点项目"
		></Banner>
		<div class="about-content">
			<div class="bilingual">
				<p>
					The VIEWpoint climate science project shares the world-class
					scientific research of the CSSP China (Climate Science for
					Service Partnership China) project, which is a collaboration
					with CMA, the IAP and UK institutions. Discover climate data
					tools, demonstrators, training resources, articles and
					videos designed for government, industry and the public.
				</p>
				<p lang="zh-cn">
					VIEWpoint气候科学项目与中国气象局（CMA）、中国科学院大气物理研究所（IAP）
					和其他一些英国机构合作，旨在分享CSSP
					China项目中的世界一流的科学研究信息。跟随我们一起发现专为政府、业界和大众设计的气候数据工具、演示工具、培训资源、文章以及视频吧！
				</p>
			</div>
			<div class="bilingual">
				<h2>
					Welcome to VIEWpoint
				</h2>
				<h2 lang="zh-cn">
					欢迎来到 VIEWpoint新观点
				</h2>
			</div>
			<div class="bilingual">
				<p>
					Welcome to VIEWpoint, where you can find insights into
					cutting-edge climate research and advanced information about
					the Sino-UK joint research project, Climate Science for
					Service Partnership (CSSP) China.
				</p>
				<p lang="zh-cn">
					欢迎来到VIEWpoint新观点，在这里您可获取有关前沿气候研究的深刻洞见以及有关中英联合研究项目——气候科学支持服务伙伴关系（CSSP）计划中国项目的进阶咨询。
				</p>
			</div>
			<div class="bilingual">
				<p>
					This successful collaboration with the
					<a
						href="http://www.cma.gov.cn/en2014/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span class="nowrap">
							<font-awesome-icon
								icon="external-link-alt"
							></font-awesome-icon>
							China
						</span>
						Meteorological Administration (CMA)</a
					>, the
					<a
						href="http://english.iap.cas.cn/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span class="nowrap">
							<font-awesome-icon
								icon="external-link-alt"
							></font-awesome-icon>
							Institute
						</span>
						of Atmospheric Physics (IAP) at the Chinese Academy of
						Sciences</a
					>
					and Chinese universities started in 2014 and has already
					produced more than 340 published scientific research papers.
					UK collaborators are led by the Met Office and include other
					key organisations in the UK.
				</p>
				<p lang="zh-cn">
					本项目成功地得到了与<a
						href="https://www.cma.gov.cn"
						target="_blank"
						rel="noopener noreferrer"
						>中国气象局</a
					>（CMA）、<a
						href="http://www.iap.cas.cn/"
						target="_blank"
						rel="noopener noreferrer"
						>中国科学院大气物理研究所</a
					>（IAP）以及多所中国高等学府的支持。 它开始于 2014
					年，到目前为止已经发表了 340
					余篇科研论文。本项目在英国方面的合作者以英国国家气象局为主，并且包括英国的一些其他重要组织。
				</p>
			</div>
			<div class="bilingual">
				<p>
					VIEWpoint is a CSSP China project, delivered by the
					<a
						href="https://www.the-iea.org"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span class="nowrap">
							<font-awesome-icon
								icon="external-link-alt"
							></font-awesome-icon>
							Institute
						</span>
						for Environmental Analytics (IEA)</a
					>, based at the University of Reading, UK. The IEA have
					produced a wide range of high-quality materials to support
					evidence-based decision-making to address climate risks
					leading to lasting benefits for China.
				</p>
				<p lang="zh-cn">
					VIEWpoint新观点隶属于CSSP China项目，由位于英国雷丁大学的<a
						href="https://www.the-iea.org"
						target="_blank"
						rel="noopener noreferrer"
						>环境分析研究所</a
					>运行。我们致力于提供各种高质量资讯，以支持证据基准的决策，从而应对气候风险，使中国社会长期受益。
				</p>
			</div>
			<div class="bilingual">
				<h2>
					Towards China’s goal of net zero emissions by 2060
				</h2>
				<h2 lang="zh-cn">
					到2060年之前实现中国净零排放目标
				</h2>
			</div>
			<div class="bilingual">
				<p>
					CSSP China is made up of many projects looking at different
					aspects of climate research relating to China’s needs and to
					the Chinese Government’s commitment to net zero emissions by
					2060. Its aim is to develop partnerships to strengthen the
					resilience of vulnerable communities to weather and climate
					variability.
				</p>
				<p lang="zh-cn">
					CSSP
					China由多个项目组成，这些项目着眼于气候研究的不同方面，涉及到中国的需求以及中国政府到2060年实现净零排放的承诺。其目的是发展合作伙伴关系，以提高生态脆弱地区对天气和气候变化的适应能力。
				</p>
			</div>
			<div class="bilingual">
				<p>
					Collaborating scientists in China and in the UK meet each
					year for a CSSP China scientific conference / workshop to
					share the latest developments, scientific progress and
					achievements. In past years each country has hosted the
					event in turn but the global pandemic has restricted travel
					and the 2020 workshop was held virtually, in October.
					Delegates were told that prototype climate services,
					developed by scientists in both countries working together,
					are already being trialled in China.
				</p>
				<p lang="zh-cn">
					中英双方的科学家每年都会出席CSSP
					China的学术讨论会/研讨会，分享最新发展、科学进展和主要成就。在过去的几年间，各国轮流举办该活动，如今全球疫情的大流行限制了人们的出行，因此今年的研讨会是在10月份以线上形式举行的。我们从代表们处获悉，由两国科学家共同开发的气候服务原型已经在中国试用。
				</p>
			</div>
			<div class="bilingual">
				<h2>
					Climate services for decision-makers in China
				</h2>
				<h2 lang="zh-cn">
					适用于中国决策者的气候服务
				</h2>
			</div>
			<div class="bilingual">
				<p>
					VIEWpoint is a CSSP China project, delivered by the
					Institute for Environmental Analytics, based at the
					University of Reading, UK, to share high quality resources,
					showcasing new data and prototype climate services in China
					and internationally.
				</p>
				<p lang="zh-cn">
					VIEWpoint是一个CSSP
					China项目，由位于英国雷丁大学的环境分析研究所运行。它的目标是分享优质资源，向中国以及全球展示新数据和气候服务原型。
				</p>
			</div>
			<div class="bilingual">
				<p>
					These resources include this website, a searchable catalogue
					of published papers, data visualisations, training resources
					and a handbook. A series of written, video and audio content
					and infographics also give insights into the research.
				</p>
				<p lang="zh-cn">
					这其中将包括网站、可检索的已发表论文的目录、可视化数据、辅助培训资源和手册。同时，一系列文字、视频和音频内容以及信息图表将为研究提供深刻见解。
				</p>
			</div>
			<div class="bilingual">
				<p>
					The aim is to raise awareness of how climate change will
					affect China and to share the newest and highest quality
					science and data available to inform plans and strategic
					decisions in the face of climate change. CSSP China is
					funded through the Newton Fund and the Department for
					Business, Energy &amp; Industrial Strategy (BEIS) UK-China
					Research Innovation Partnership Fund.
				</p>
				<p lang="zh-cn">
					该项目致力于提高人们对中国气候变化的认识，并分享最新、最高质量的科学知识和数据，从而为应对气候变化的计划和战略决策提供信息。
					CSSP
					China由牛顿基金以及商业、能源与产业战略部（BEIS）的中英研究与创新合作基金资助。
					<br />
				</p>
			</div>
			<table class="acronym">
				<tr>
					<td><strong>V</strong>isualisation</td>
					<td lang="zh-cn">可视化</td>
				</tr>
				<tr>
					<td><strong>I</strong>nnovation</td>
					<td lang="zh-cn">创新</td>
				</tr>
				<tr>
					<td><strong>E</strong>ngagement</td>
					<td lang="zh-cn">交互</td>
				</tr>
				<tr>
					<td><strong>W</strong>eb-enabled</td>
					<td lang="zh-cn">网络化</td>
				</tr>
				<tr class="viewpoint">
					<td colspan="2">
						<hr />
						<img
							alt="VIEWpoint logo"
							src="../assets/images/logo.png"
						/>
					</td>
				</tr>
			</table>
		</div>
		<Gotop></Gotop>
	</div>
</template>

<script>
import Banner from './Banner.vue'
import Gotop from './Gotop.vue'

// animation on scroll based on https://cssanimation.rocks/scroll-animations/
// requestionAnimationFrame happens every 1/60s
// if not supported by browser, setTimeout is used instead
let scroll =
	window.requestAnimationFrame ||
	function(callback) {
		window.setTimeout(callback, 1000 / 60)
	}

export default {
	name: 'About',
	components: {
		Banner,
		Gotop
	},
	data() {
		return {
			firefox: false
		}
	},
	methods: {
		isAcronymInViewport() {
			const table = document.getElementsByClassName('acronym')
			if (
				table.length == 0 ||
				table[0].classList.contains('js-in-viewport')
			) {
				// this has been done already (only needs to be done once)
				return
			}
			const rect = table[0].getBoundingClientRect()
			if (
				(rect.top <= 0 && rect.bottom >= 0) ||
				(rect.bottom >=
					(window.innerHeight ||
						document.documentElement.clientHeight) &&
					rect.top <=
						(window.innerHeight ||
							document.documentElement.clientHeight)) ||
				(rect.top >= 0 &&
					rect.bottom <=
						(window.innerHeight ||
							document.documentElement.clientHeight))
			) {
				// table.acronym is in view so add animation classes to table and rows
				table[0].classList.add('js-in-viewport')
				const tr = table[0].getElementsByTagName('tr')
				for (let i = 0; i < tr.length; i++) {
					tr[i].classList.add('widen')
				}
			} else {
				// wait 1/60 of a second and check again
				scroll(this.isAcronymInViewport)
			}
		}
	},
	mounted() {
		if (navigator.userAgent.indexOf('Firefox/') >= 0) {
			this.firefox = true
		}
		// start listening for table.acronym to come into viewport
		this.isAcronymInViewport()
	}
}
</script>

<style scoped>
.banner-style {
	background-image: url('../assets/images/about-banner.jpg');
	box-shadow: inset 0 0 0 1000px rgba(217, 216, 214, 0.5);
}

.about-content {
	width: 80%;
	max-width: 960px;
	margin: 32px auto;
}

.bilingual {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
h2,
p {
	width: 48%;
}

h2 {
	color: var(--vpOrange);
	margin-top: 24px;
}

p {
	margin-top: 12px;
}

a {
	text-decoration: none;
	outline: 0;
}
a:lang(zh-cn) {
	text-decoration: underline;
	color: var(--chineseAnchor);
}
@media (hover: hover) {
	a:hover,
	a:hover span,
	a:hover svg path {
		color: var(--vpOrange);
	}
}

.acronym {
	border-collapse: separate;
	margin: 32px auto 0 auto;
	width: 90%;
	background: var(--primaryLightest);
	border: 2px solid var(--vpOrange);
}
.acronym tr {
	transform: scaleX(0);
}
.acronym td {
	background: var(--primaryLightest);
	border: transparent;
	padding: 4px 8px;
	width: 50%;
	font-size: 1.1rem;
	color: var(--vpOrange);
}
.acronym td strong {
	background: transparent;
	font-size: inherit;
	color: var(--vpOrange);
}
.acronym tr:first-of-type td {
	padding-top: 8px;
}
.acronym td:first-of-type {
	text-align: right;
}
.acronym tr.viewpoint td {
	text-align: center;
	padding-bottom: 8px;
}
/*.acronym tr.viewpoint span {
	background: transparent;
	color: var(--vpOrange);
	font-size: 1.2rem;
	transition: opacity 0.3s ease-in-out, transform 0s linear 0.3s;
}*/
.acronym tr.viewpoint td hr {
	width: 50%;
	margin: 0 auto 4px auto;
	border-color: var(--primaryLighter);
	border-bottom: none;
}
.acronym tr.viewpoint td img {
	width: calc(max(20%, 120px));
	background: transparent;
	transition: all 0.3s ease-in-out 0.3s;
}

/* widen classes added only once table is in viewport */
.acronym tr.widen {
	transform-origin: center;
	transition: transform 3s ease-out;
	transform: scaleX(1);
}
.acronym tr.widen:nth-of-type(2) {
	transition-delay: 1s;
}
.acronym tr.widen:nth-of-type(3) {
	transition-delay: 2s;
}
.acronym tr.widen:nth-of-type(4) {
	transition-delay: 3s;
}
.acronym tr.widen:nth-of-type(5) {
	transition-delay: 4s;
}
/* firefox doesn't do scaleX very well for <img>s so will just fade it in */
@supports (-moz-appearance: none) and (text-align-last: auto) {
	.acronym tr.viewpoint {
		transform: scaleX(1);
	}
	.acronym tr.viewpoint td hr,
	.acronym tr.viewpoint td img {
		opacity: 0;
	}
	.acronym tr.viewpoint.widen td hr,
	.acronym tr.viewpoint.widen td img {
		transition: opacity 3s ease-out;
		transition-delay: 5s;
		opacity: 1;
	}
}

@media (max-width: 1007px) {
	.about-content {
		width: 88%;
		margin: 16px auto;
	}
	.acronym {
		margin-top: 16px;
	}
	.acronym tr.viewpoint td hr {
		width: 75%;
	}
}

@media (max-width: 640px) {
	.about-content {
		width: 92%;
		margin: 8px auto 12px auto;
	}
	.bilingual {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	h2,
	p {
		width: 100%;
	}
	h2:lang(zh-cn) {
		margin-top: 2px;
	}
	.acronym tr.viewpoint td hr {
		width: 90%;
	}
}
</style>
