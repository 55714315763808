var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"infographics"}},[_c('Banner',{staticClass:"banner-style",attrs:{"enTitle":"Infographics","cnTitle":"信息图表"}}),_vm._m(0),_c('div',{staticClass:"wrapper"},_vm._l((_vm.pdfs),function(doc,i){return _c('div',{key:i,ref:("panel-" + i),refInFor:true,staticClass:"button-panel-wrapper"},[_c('div',{staticClass:"button-panel",class:{ alt: i % 2 == 0 }},[_c('h2',[_vm._v(_vm._s(i + 1))]),(doc.enPdf)?_c('a',{staticClass:"title english clickable",attrs:{"href":require(("../assets/pdfs/" + (doc.enPdf) + ".pdf")),"download":("VIEWpoint-" + (doc.enPdf) + ".pdf"),"target":"_blank","rel":"noopener noreferrer"}},[(doc.enDraft)?_c('p',{staticClass:"draft"},[_vm._v("Draft")]):_vm._e(),_c('h3',[_vm._v(_vm._s(doc.enTitle))]),(doc.enPdf)?_c('font-awesome-icon',{attrs:{"icon":"download"}}):_vm._e()],1):_c('div',{staticClass:"title"},[(doc.enDraft)?_c('p',{staticClass:"draft"},[_vm._v("Draft")]):_vm._e(),_c('h3',[_vm._v(_vm._s(doc.enTitle))])]),_c('div',{staticClass:"img-wrapper",class:{ double: doc.cnPdf }},[(
							!doc.cnPdf ||
								!_vm.narrowPage ||
								(_vm.narrowPage && _vm.language == 'en')
						)?_c('img',{staticClass:"button-img",class:{
							'in-preview':
								_vm.preview.index == i && _vm.preview.lang == 'en'
						},attrs:{"src":require(("../assets/images/infographic-" + (i +
								1) + ".jpg"))},on:{"click":function($event){return _vm.setPreview(i, 'en')}}}):_vm._e(),(
							doc.cnPdf &&
								(!_vm.narrowPage ||
									(_vm.narrowPage && _vm.language == 'cn'))
						)?_c('img',{staticClass:"button-img",class:{
							'in-preview':
								_vm.preview.index == i && _vm.preview.lang == 'cn'
						},attrs:{"src":require(("../assets/images/infographic-" + (i +
								1) + "-cn.jpg"))},on:{"click":function($event){return _vm.setPreview(i, 'cn')}}}):_vm._e()]),(doc.cnPdf)?_c('a',{staticClass:"title chinese clickable",attrs:{"href":require(("../assets/pdfs/" + (doc.cnPdf) + ".pdf")),"download":("VIEWpoint-" + (doc.cnPdf) + ".pdf"),"target":"_blank","rel":"noopener noreferrer"}},[(doc.cnDraft)?_c('p',{staticClass:"draft"},[_vm._v("Draft")]):_vm._e(),(doc.cnTitle)?_c('h3',{attrs:{"lang":"zh-cn"}},[_vm._v(" "+_vm._s(doc.cnTitle)+" ")]):_c('p',[_vm._v(_vm._s(doc.enTitle)+" in Chinese")]),(doc.cnPdf)?_c('font-awesome-icon',{attrs:{"icon":"download"}}):_vm._e()],1):(doc.cnTitle)?_c('div',{staticClass:"title"},[_c('h3',{attrs:{"lang":"zh-cn"}},[_vm._v(_vm._s(doc.cnTitle))]),_c('p',{staticStyle:{"opacity":"0.3"},attrs:{"lang":"zh-cn"}},[_vm._v(" 暂无中文版 ")])]):_c('div',{staticClass:"title"},[_c('p',{attrs:{"lang":"zh-cn"}},[_vm._v(" 暂无中文版 ")])])]),_c('div',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.preview.index == i),expression:"preview.index == i"}],staticClass:"preview",class:{ alt: i % 2 == 0 },on:{"slide-open-end":_vm.previewOpened,"slide-close-end":_vm.previewClosed}},[(_vm.previewMode == 'iframe')?_c('iframe',{attrs:{"src":require(("../assets/pdfs/" + (_vm.preview.lang == 'cn' && doc.cnPdf
								? doc.cnPdf
								: doc.enPdf) + ".pdf")) + '#statusbar=1&toolbar=0'}},[_vm._v(" This browser does not support PDFs ")]):(_vm.preview.lang == 'cn' && doc.cnPdf)?_c('img',{attrs:{"src":require(("../assets/images/infographic-" + (i +
							1) + "-cn.jpg"))}}):(_vm.preview.lang == 'en')?_c('img',{attrs:{"src":require(("../assets/images/infographic-" + (i + 1) + ".jpg"))}}):_vm._e(),_c('div',{staticClass:"preview-icons"},[(_vm.preview.lang == 'en' && doc.enPdf)?_c('a',{staticClass:"clickable",attrs:{"href":require(("../assets/pdfs/" + (doc.enPdf) + ".pdf")),"download":("VIEWpoint-" + (doc.enPdf) + ".pdf"),"target":"_blank","rel":"noopener noreferrer"}},[_c('font-awesome-icon',{attrs:{"icon":"download"}})],1):(_vm.preview.lang == 'cn' && doc.cnPdf)?_c('a',{staticClass:"clickable",attrs:{"href":require(("../assets/pdfs/" + (doc.cnPdf) + ".pdf")),"download":("VIEWpoint-" + (doc.cnPdf) + ".pdf"),"target":"_blank","rel":"noopener noreferrer"}},[_c('font-awesome-icon',{attrs:{"icon":"download"}})],1):_vm._e(),_c('font-awesome-icon',{staticClass:"close-icon clickable",attrs:{"icon":"times"},on:{"click":function($event){return _vm.setPreview(-1)}}})],1)])])}),0),_c('Gotop')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-page"},[_c('div',{staticClass:"bilingual"},[_c('p',[_vm._v(" The Met Office have produced these high quality infographics as quick reference documents on important scientific topics for China. They are an easy way to find out a lot on the science in one place. ")]),_c('p',{attrs:{"lang":"zh-cn"}},[_vm._v(" 英国气象局制作了这些高质量的信息图表，以作为一系列中国重要科学课题项目的快速参考文件。这些信息图表可以帮助读者轻松获取大量的科学信息。 ")])])])}]

export { render, staticRenderFns }