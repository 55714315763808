<template>
	<div>
		<h1>Ongoing tutorials</h1>
		<p>
			Keep checking for 
			<a
				href="https://umep-workshop.readthedocs.io/en/latest/NEW/New0.html"
				target="_blank" rel="noopener noreferrer"
				class="nowrap"
			>
				<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
				new tutorials</a
			> on the UMEP workshop website since tutorials are often added.
		</p>
	</div>
</template>

<script>
export default {
	name: "Tutorials"
}
</script>

<style scoped>
</style>