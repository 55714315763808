<template>
	<div>
		<h1>References</h1>
		<p>
			For scientific references, see the 
			<a
				href="https://umep-workshop.readthedocs.io/en/latest/References/Ref0.html"
				target="_blank" rel="noopener noreferrer"
				class="nowrap"
			>
				<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
				UMEP workshop website</a
			>.
		</p>
	</div>
</template>

<script>
export default {
	name: "References"
}
</script>

<style scoped>
</style>