<template>
	<div id="app">
		<div
			class="eol"
			:class="
				firstVisit
					? 'eol-block'
					: navPage === 'Catalogue'
					? 'eol-hidden'
					: 'eol-banner'
			"
		>
			<div
				v-if="firstVisit"
				class="eol-close"
				@click="firstVisit = false"
			>
				X
			</div>
			<p>
				Please note: This site is no longer maintained.
				<span v-if="firstVisit">
					<br />
					Recent papers will not be added to the catalogue. Some
					external links could become unavailable.
				</span>
				<span v-else class="more" @click="firstVisit = true">
					More...
				</span>
			</p>
			<p v-if="firstVisit">
				For the latest information about the Weather and Climate Science
				for Service Partnership Programmes (WCSSP), please go to
				<a
					href="https://www.metoffice.gov.uk/wcssp"
					target="_blank"
					rel="noopener noreferer"
				>
					https://www.metoffice.gov.uk/wcssp
					<font-awesome-icon
						icon="external-link-alt"
					></font-awesome-icon></a
				>.
			</p>
		</div>
		<NavMenu class="app-fixed" :navPage="navPage"></NavMenu>
		<transition name="fade" mode="out-in">
			<router-view>
				<component :is="navPage"></component>
			</router-view>
		</transition>
		<Footer class="app-fixed"></Footer>
	</div>
</template>

<script>
import NavMenu from './components/NavMenu.vue'
import Footer from './components/Footer.vue'
import About from './components/About.vue'
import Briefing from './components/Briefing.vue'
import Catalogue from './components/Catalogue.vue'
import Demonstrators from './components/Demonstrators.vue'
import Explainers from './components/Explainers.vue'
import Handbook from './components/Handbook.vue'
import Home from './components/Home.vue'
import Infographics from './components/Infographics.vue'
import Glossary from './components/Glossary.vue'
import Resources from './components/Resources.vue'
import Training from './components/Training.vue'
import Videos from './components/Videos.vue'

export default {
	name: 'App',
	components: {
		NavMenu,
		Footer,
		About,
		Briefing,
		Catalogue,
		Demonstrators,
		Explainers,
		Glossary,
		Handbook,
		Home,
		Infographics,
		Resources,
		Training,
		Videos
	},
	data() {
		return {
			navPage: '',
			firstVisit: false
		}
	},
	watch: {
		navPage(newPage, oldPage) {
			if ('scrollBehavior' in document.documentElement.style) {
				window.scrollTo({ top: 0, behavior: 'smooth' })
			} else {
				// EdgeHTML scrolls to top by scrolling the .nav-menu into view
				const navFtr = document.getElementsByClassName('app-fixed')
				navFtr[0].scrollIntoView(true)
			}
			if (oldPage) this.firstVisit = false
		},
		$route(to) {
			this.navPage = to.name
		}
	},
	methods: {
		resized() {
			// recalculate --minContentHeight using 100vh and deducting
			// App.vue's NavMenu and Footer (since this component sits between them)
			let usedHeight = 0
			const appFixed = document.getElementsByClassName('app-fixed')
			if (appFixed.length) {
				for (let i = 0; i < appFixed.length; i++) {
					usedHeight += appFixed[i].getBoundingClientRect().height
				}
			}
			if (usedHeight) {
				document.documentElement.style.setProperty(
					'--minContentHeight',
					`${window.innerHeight - usedHeight}px`
				)
			}
		}
	},
	mounted() {
		const visited = localStorage.getItem('viewpoint')
		if (!visited) {
			this.firstVisit = true
			setTimeout(() => {
				const d = new Date()
				localStorage.setItem('viewpoint', d.toISOString())
				this.firstVisit = false
			}, 20000)
		}
		this.navPage = this.$route.name
		this.resized() /* reset size-based CSS vars immediately on loading */
		window.addEventListener('resize', this.resized)
		window.addEventListener('orientationchange', this.resized)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resized)
		window.removeEventListener('orientationchange', this.resized)
	}
}
</script>

<style>
@import './assets/css/viewpoint-cssp.css';

#app {
	overflow-x: hidden;
}

.eol {
	width: 100%;
	background: var(--vpOrange);
	text-align: center;
	transition: all 0.2s linear;
}
.eol p {
	color: var(--text);
	text-wrap: balance;
	background: inherit;
}
.eol a,
.eol span {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	background: inherit;
}
.eol svg {
	background: inherit;
}

.eol-block {
	padding: 2rem 4rem 1rem 4rem;
	position: relative;
}
.eol-close {
	position: absolute;
	top: 0.75rem;
	right: 1rem;
	width: 48px;
	height: 48px;
	text-align: right;
	cursor: pointer;
	font-weight: bold;
	background: inherit;
}
.eol-block p {
	font-size: 120%;
	line-height: 120%;
	margin-bottom: 1rem;
}

.eol-banner {
	padding: 0.25rem;
}
.eol-banner p {
	font-size: 90%;
	line-height: 90%;
}

.eol-hidden {
	height: 0;
}

.more {
	opacity: 0.65;
	text-decoration: underline;
	cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

@media print {
	.app-fixed {
		display: none !important;
	}
}
</style>
