import { render, staticRenderFns } from "./TrainingData.vue?vue&type=template&id=56b63499&scoped=true&"
import script from "./TrainingData.vue?vue&type=script&lang=js&"
export * from "./TrainingData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b63499",
  null
  
)

export default component.exports