<template>
	<div>
		<h1>UMEP technical support</h1>
		<p>
			There is a
			<a
				href="https://umep-workshop.readthedocs.io/en/latest/NeedHelp.html#needhelp"
				target="_blank" rel="noopener noreferrer"
				class="nowrap"
			>
				<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
				Help page</a
			>
			that contains all the links required to contact the creators of UMEP
			for assistance, guidance for updating, the manual, and the bug
			reporter.
		</p>
		<p>
			It also contains manuals, papers and update guidance for SuPy,
			SUEWS, QGIS, Jupyter Notebooks, Python and Github.
		</p>
		<p>
			See the
			<a
				href="https://umep-workshop.readthedocs.io/en/latest/FAQ.html#faq"
				target="_blank" rel="noopener noreferrer"
				class="nowrap"
			>
				<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
				Frequently Asked Questions (FAQ)</a
			>
			for troubleshooting common problems.
		</p>
	</div>
</template>

<script>
export default {
	name: 'Support'
}
</script>

<style scoped></style>
